import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { fetchServiceDetailsLatestAPI } from "../../../../shared/hooks/useFetchServiceDetails";
import API_URLS from "../../../../shared/utils/ApiUrls";
import { errorConditionGTM } from "../../Utils/googleTags";
import store from "../store";
import { getServiceAddressFromUser } from "../../Utils/utils";

type serviceDetailsInitialStateType = {
  serviceDetails: any;
  loading: boolean;
  error: any;
};
const initialState: serviceDetailsInitialStateType = {
  serviceDetails: null,
  loading: false,
  error: null,
};

export const getserviceDetails = createAsyncThunk(
  "service/details",
  async (user: any) => {
    const { email, billingAccountNumber, serviceAddrId = '' } = user;
    try {
      const reduxStore = store.getState();
      const headers = reduxStore?.brightspeedToken?.headers;
      const serviceAddLocal = getServiceAddressFromUser(user);

      const response = await fetchServiceDetailsLatestAPI(
        {
          "": `${API_URLS?.getServiceDetails_with_ban}?ban=${billingAccountNumber}&addressId=${serviceAddrId || serviceAddLocal?.addressId}`,
        },
        headers
      );
      let ataProdIndex = null;
      if (response?.data?.subscriptions[0]?.serviceDetails?.length > 0) {
        response.data.subscriptions[0].serviceDetails =
          response?.data?.subscriptions[0]?.serviceDetails?.map((item: any) => {
            if (item?.serviceName) {
              item.serviceName = item?.serviceName
                ?.toString()
                ?.trim()
                ?.replace("fQF ", "");
            }
            return item;
          });
      }
      const ataProduct = response?.data?.subscriptions[0]?.serviceDetails?.find(
        (item: any, i: number) => {
          if (
            item?.serviceType?.trim()?.toUpperCase() === "DEVICE TYPE" &&
            item?.serviceState?.trim()?.toUpperCase() === "ACTIVE"
          ) {
            ataProdIndex = i;
            return item;
          }
        }
      );
      if(response?.data?.subscriptions?.length > 0 && response?.data?.subscriptions[0]?.serviceDetails) {
        let arrServices = response?.data?.subscriptions[0]?.serviceDetails?.filter((item: any) => item?.serviceName?.trim() !=="" && item?.serviceState !== "Inactive" && item?.serviceName !== "Quantum Fiber Tech Installation" && item?.serviceType?.toUpperCase() !== "DEVICE TYPE" && item?.isParent !== "false");
        response.data.serviceIDForAxonAPI = arrServices?.length > 0 ? arrServices[0]?.serviceId : ""; 
      }
      if (ataProduct?.serviceName && ataProduct?.serviceId) {
        response.data.ataProduct = ataProduct;
        response.data?.subscriptions[0].serviceDetails?.splice(ataProdIndex, 1);
      }
      // find ProductType logic for timeslot API
      if (
        response?.data?.subscriptions?.length > 0 &&
        response?.data?.subscriptions[0]?.serviceDetails?.length > 0
      ) {
        let internet = false;
        let voice = false;
        for (
          let index = 0;
          index < response?.data?.subscriptions[0]?.serviceDetails?.length;
          index++
        ) {
          const element =
            response?.data?.subscriptions[0]?.serviceDetails[index];
          if (element?.productType?.toUpperCase() === "WA") {
            internet = true;
          } else if (element?.productType?.toUpperCase() === "IL") {
            voice = true;
          }
        }
        if (internet && voice) {
          response.data.productType = "HSI_VOICE";
        } else if (internet && !voice) {
          response.data.productType = "HSI";
        } else if (!internet && voice) {
          response.data.productType = "VOICE";
        }
      }
      //Network settings link display changes
      if (response?.data?.subscriptions?.length > 0 && response?.data?.subscriptions[0]?.serviceDetails?.length > 0) {
        let activeInternet = response?.data?.subscriptions[0]?.serviceDetails?.filter((item: any) => item?.serviceState?.toUpperCase() === "ACTIVE" && item?.isParent?.toUpperCase() === "TRUE" && item?.productType?.toUpperCase() === "WA");
        let activeVoice = response?.data?.subscriptions[0]?.serviceDetails?.filter((item: any) => item?.serviceState?.toUpperCase() === "ACTIVE" && item?.isParent?.toUpperCase() === "TRUE" && item?.productType?.toUpperCase() === "IL");        
        if (activeInternet.length === 0 && activeVoice.length > 0) {
          response.data.voiceOnly = true;
        }
      }
      const pendingService =
        (response?.data?.subscriptions?.length > 0 &&
          response?.data?.subscriptions[0]?.serviceDetails?.find(
            (item: any) => {
              if (
                reduxStore?.user?.data?.currentStatus === "Active" &&
                ["CHANGE PENDING", "INSTALLATION PENDING"]?.includes(
                  item?.serviceState?.toUpperCase()
                )
              ) {
                return item;
              }
            }
          )) ||
        null;

      if (pendingService?.serviceId) {
        localStorage.removeItem("changePending");
        response.data.serviceIsInPending = true;
      } else if (
        !pendingService?.serviceId &&
        localStorage.getItem("changePending") === serviceAddLocal
      ) {
        response.data.serviceIsInPending = true;
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const serviceDetailSlice = createSlice({
  name: "service/details",
  initialState,
  reducers: {
    clearServiceDetailSlice: (state) => {
      return initialState;
    },
    serviceDetailSliceSetInitialState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getserviceDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getserviceDetails.fulfilled, (state, action) => {
      state.serviceDetails = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getserviceDetails.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "service details",
        action.error?.message,
        action?.type
      );
      state.serviceDetails = null;
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const { clearServiceDetailSlice, serviceDetailSliceSetInitialState } =
  serviceDetailSlice.actions;
export default serviceDetailSlice.reducer;
