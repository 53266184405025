import { createSlice, PayloadAction } from '@reduxjs/toolkit';
 
export interface BrightSpeedUserUtilsSlice {
  accountType: "POSTPAID" | "PREPAID";
  accountStatus: string;
  accountTenure: string;
  isUserHaveAutoPay: boolean;
  isUserHavePaperlessBilling: boolean;
  currentAmountDue: string;
  billDueDate: string;
  lastInvoiceAmount: string;
  lastBillDate: string;
  userCurrentInternet_plan_speed: string;
  voice: string;
  userStateCode: string;
  userWire_center: string;
  isCTLPrepayUser: boolean;
  isCTLPostPayUser: boolean;
  isCTLMigratedPrepay: boolean;
  isCTLMigratedPostpay: boolean;
  is4gltePostpay: boolean;
  isQF: boolean;
  isQF_II: boolean;
  isBRSPD: boolean;
  can_GTM_Tag_Trigger: boolean;
  GTM_Tag_Triggered_For: string,
}
 
const initialState: BrightSpeedUserUtilsSlice = {
  accountType: "PREPAID",
  accountStatus: "",
  accountTenure: "",
  isUserHaveAutoPay: false,
  isUserHavePaperlessBilling: false,
  currentAmountDue: "",
  billDueDate: "",
  lastInvoiceAmount: "",
  lastBillDate: "",
  userCurrentInternet_plan_speed: "",
  voice: "",
  userStateCode: "",
  userWire_center: "",
  isCTLPrepayUser: false,
  isCTLPostPayUser: false,
  is4gltePostpay: false,
  isCTLMigratedPrepay: false,
  isCTLMigratedPostpay: false,
  isQF: false,
  isQF_II: false,
  isBRSPD: false,
  can_GTM_Tag_Trigger: false,
  GTM_Tag_Triggered_For: localStorage.getItem("gtm_trigger_for") || ""
};
 
const brightSpeedUserUtils = createSlice({
  name: 'brightSpeedUserUtils',
  initialState,
  reducers: {
    setAccountType(state, action: PayloadAction<"POSTPAID" | "PREPAID">) {
      state.accountType = action.payload;
    },
    setAccountStatus(state, action: PayloadAction<string>) {
      state.accountStatus = action.payload;
    },
    setAccountTenure(state, action: PayloadAction<string>) {
      state.accountTenure = action.payload;
    },
    setIsUserHaveAutoPay(state, action: PayloadAction<boolean>) {
      state.isUserHaveAutoPay = action.payload;
    },
    setIsUserHavePaperlessBilling(state, action: PayloadAction<boolean>) {
      state.isUserHavePaperlessBilling = action.payload;
    },
    setCurrentAmountDue(state, action: PayloadAction<string>) {
      state.currentAmountDue = action.payload;
    },
    setBillDueDate(state, action: PayloadAction<string>) {
      state.billDueDate = action.payload;
    },
    setLastInvoiceAmount(state, action: PayloadAction<string>) {
      state.lastInvoiceAmount = action.payload;
    },
    setLastBillDate(state, action: PayloadAction<string>) {
      state.lastBillDate = action.payload;
    },
    setInternetPlanSpeed(state, action: PayloadAction<string>) {
      state.userCurrentInternet_plan_speed = action.payload;
    },
    setVoice(state, action: PayloadAction<string>) {
      state.voice = action.payload;
    },
    setUserStateCode(state, action: PayloadAction<string>) {
      state.userStateCode = action.payload;
    },
    setUserWireCenter(state, action: PayloadAction<string>) {
      state.userWire_center = action.payload;
    },
    setIsCTLPrepayStatus(state, action: PayloadAction<boolean>) {
      state.isCTLPrepayUser = action.payload;
    },
    setIsCTLPostPayStatus(state, action: PayloadAction<boolean>) {
      state.isCTLPostPayUser = action.payload;
    },
    setIs4gltePostpay(state, action: PayloadAction<boolean>) {
      state.is4gltePostpay = action.payload;
    },
    setIsQF(state, action: PayloadAction<boolean>) {
      state.isQF = action.payload;
    },
    setIsQF_II(state, action: PayloadAction<boolean>) {
      state.isQF_II = action.payload;
    },
    setIsBRSPD(state, action: PayloadAction<boolean>) {
      state.isBRSPD = action.payload;
    },
    setIsCTLMigratedPrepay(state, action: PayloadAction<boolean>) {
        state.isCTLMigratedPrepay = action.payload;
    },
    setIsCTLMigratedPostpay(state, action: PayloadAction<boolean>) {
        state.isCTLMigratedPostpay = action.payload;
    },
    setCanGTM_Tag_Trigger(state, action: PayloadAction<boolean>) {
      state.can_GTM_Tag_Trigger = action.payload;
    },
    setGTM_TagTriggerFor(state, action: PayloadAction<string>) {
        localStorage.setItem("gtm_trigger_for", action.payload);
        state.GTM_Tag_Triggered_For = action.payload;
    },
    resetUseUserSlice: () => initialState
  },
});
 
export const {
  setAccountType,
  setAccountStatus,
  setAccountTenure,
  setIsUserHaveAutoPay,
  setIsUserHavePaperlessBilling,
  setCurrentAmountDue,
  setBillDueDate,
  setLastInvoiceAmount,
  setLastBillDate,
  setInternetPlanSpeed,
  setVoice,
  setUserStateCode,
  setUserWireCenter,
  setIsCTLPrepayStatus,
  setIsCTLPostPayStatus,
  setIs4gltePostpay,
  setIsQF,
  setIsQF_II,
  setIsBRSPD,
  setIsCTLMigratedPrepay,
  setIsCTLMigratedPostpay,
  setCanGTM_Tag_Trigger,
  setGTM_TagTriggerFor,
  resetUseUserSlice
} = brightSpeedUserUtils.actions;
 
export default brightSpeedUserUtils.reducer;